import _callsites from "callsites";
var exports = {};
const callsites = _callsites;

exports = filepath => {
  const stacks = callsites();

  if (!filepath) {
    return stacks[2].getFileName();
  }

  let seenVal = false; // Skip the first stack as it's this function

  stacks.shift();

  for (const stack of stacks) {
    const parentFilepath = stack.getFileName();

    if (typeof parentFilepath !== "string") {
      continue;
    }

    if (parentFilepath === filepath) {
      seenVal = true;
      continue;
    } // Skip native modules


    if (parentFilepath === "module.js") {
      continue;
    }

    if (seenVal && parentFilepath !== filepath) {
      return parentFilepath;
    }
  }
};

export default exports;